import React from 'react';
import Team1 from './../assets/teams/team1.png';
import Team2 from './../assets/teams/team2.jpg';
import Team3 from './../assets/teams/team3.png';
import Team4 from './../assets/teams/team4.png';
import Team5 from './../assets/teams/team5.png';
import { Fade } from "react-awesome-reveal";
export const TeamSection = () => {
  return (
    <>
    {
      /** 
       *     <section id="teamSection" className="onDiagonalSplitterUp">

       */
    }
    <section id="teamSection">
      <div className="background-overlay">

        <div className="container">

          <div className="row">
            <div className="col text-center">
              <h1 className="title mt-4">The Team</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-6 text-center">

            <Fade cascade triggerOnce delay={200} direction="up">
              <img src={Team1} alt="team1" className="teamimage" />
              </Fade>

              <div className="ptitle">PxGnome</div>
              <div className="psubtitle">Founder & All Things Tech</div>
              <div className="psocial">
                <a href="https://twitter.com/#" target="_blank" rel="noreferrer"><i className="bi bi-twitter"></i></a>
                <a href="https://twitter.com/#" target="_blank" rel="noreferrer"><i className="bi bi-instagram"></i></a>
              </div>
            </div>
            <div className="col-6 text-center">
                <Fade cascade triggerOnce delay={200} direction="up">
              <img src={Team2} alt="team2" className="teamimage" />
              </Fade>

              <div className="ptitle">PxPaws</div>
              <div className="psubtitle">Founder & All Things Non-Tech</div>
              <div className="psocial">
                <a href="https://twitter.com/#" target="_blank" rel="noreferrer"><i className="bi bi-twitter"></i></a>
                <a href="https://twitter.com/#" target="_blank" rel="noreferrer"><i className="bi bi-instagram"></i></a>
              </div>
            </div>
            <div className="col-6 text-center">
                <Fade cascade triggerOnce delay={200} direction="up">
              <img src={Team3} alt="team3" className="teamimage" />
              </Fade>

              <div className="ptitle">Aoife</div>
              <div className="psubtitle">Project Manager</div>
              <div className="psocial">
                <a href="https://twitter.com/#" target="_blank" rel="noreferrer"><i className="bi bi-twitter"></i></a>
                <a href="https://twitter.com/#" target="_blank" rel="noreferrer"><i className="bi bi-instagram"></i></a>
              </div>
            </div>
            <div className="col-6 text-center">
                <Fade cascade triggerOnce delay={200} direction="up">
                <img src={Team4} alt="team4" className="teamimage" />
                </Fade>

              <div className="ptitle">Minq</div>
              <div className="psubtitle">UI Graphics Design</div>
              <div className="psocial">
                <a href="https://twitter.com/#" target="_blank" rel="noreferrer"><i className="bi bi-twitter"></i></a>
                <a href="https://twitter.com/#" target="_blank" rel="noreferrer"><i className="bi bi-instagram"></i></a>
              </div>
            </div> 
          </div>
          <div className="row">
          </div>





        </div>
      </div>
    </section>
    </>
  );
};
