import React from "react";
import { Fade } from "react-awesome-reveal";
import Logo from "./../assets/logo/logo.png";
import CharBlack from "./../assets/hero_banner-black.png";
import Char from "./../assets/hero_banner.png";
import HeroBanner from "./../assets/banner/bg_base.png"; 
import VideoBanner from './../assets/Idol_Idol_Promo_Trailer_Video.mp4';

export const Header = () => { 

  const clickHandler = (e: any) => {
    e.preventDefault();
    const href = e.target.getAttribute("href");

    document.querySelector(href).scrollIntoView({
      behavior: "smooth",
    });
  };
  React.useEffect(() => {
    var w = window.innerWidth;
    window.addEventListener(
      "scroll",
      function () { 
        if(document.body.scrollTop >= 60) {
          var navbar = document.getElementById("navbar");
          if(!navbar?.classList.contains('fixed-navbar')){
            navbar?.classList.add("fixed-navbar");
            var menus = document.querySelectorAll<HTMLInputElement>("#headerMainMenu .menus li a.nav-link");
            if(menus.length > 0){
              for(var i=0;i < menus.length;i++){
                var menu = menus[i];
                menu.style.color = "black";
              }
            }
          }
        }else{
          var navbar = document.getElementById("navbar");
          navbar?.classList.remove("fixed-navbar");
          var menus = document.querySelectorAll<HTMLInputElement>("#headerMainMenu .menus li a.nav-link");
          if(menus.length > 0){
            for(var i=0;i < menus.length;i++){
              var menu = menus[i];
              menu.style.color = "white";
              if(w < 1000){
                menu.style.color = "black";
              }
            }
          }
        }
      },
      true
    );  
    return () => {
      window.removeEventListener("scroll", function () {}, true); 
    };
  });

   
  return (
    <>
    <header id="header">
      {/* <div className="wrap">
        <img src={CharBlack} />
      </div> */}   
      <video id="video-banner" autoPlay muted loop preload="auto" controls={false}>
        <source src={VideoBanner} type="video/mp4"/>
      </video>
      
      <img className="logo-in-mobile" src={Logo} />
      <div id="navbar" className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid d-flex justify-content-between">
          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#headerMainMenu"
          >
            <i className="bi bi-list"></i>
          </button>
          <div
            className="justify-content-center collapse navbar-collapse"
            id="headerMainMenu"
          >
            <ul className="menus navbar-nav">
              <li className="nav-item">
                <a
                  onClick={(event: any) => {
                    clickHandler(event);
                  }}
                  className="nav-link"
                  href="#ourCreatorSection"
                >
                  Creators
                </a>
              </li>
              <li className="nav-item">
                <a
                  onClick={(event: any) => {
                    clickHandler(event);
                  }}
                  className="nav-link"
                  href="#gameSection"
                >
                  The Game
                </a>
              </li>
              <li className="nav-item">
                <a
                  onClick={(event: any) => {
                    clickHandler(event);
                  }}
                  className="nav-link"
                  href="#teamSection"
                >
                  The Team
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://docs.google.com/document/d/1mU57yyM8an9dWSyUBO14zpOG2vK9HnNrChDU7WwlM84/edit?usp=sharing"
                  target="_blank"
                >
                  Whitepaper
                </a>
              </li>
            </ul>
            <div className="social-link">
              <a
                href="https://twitter.com/Idolidol_Game"
                target="_blank"
                className="footer-social-link w-inline-block"
              >
                <i className="bi bi-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/idolidol_official/"
                target="_blank"
                className="footer-social-link w-inline-block"
              >
                <i className="bi bi-instagram"></i>
              </a>
              <a
                href="https://discord.gg/bfmgcbrCsW"
                target="_blank"
                className="footer-social-link w-inline-block"
              >
                <i className="bi bi-discord"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-container">
          {/* 
        <div className="hero-banner">
          <img className="hero-banner" src={CharBlack} />
        </div>
      <div className="logo-wrapper animate__animated animate__flipInX animate__delay">
          <img className="logo" src={Logo} />
        </div> */}
        <div className="video-wrapper  animate__animated animate__fadeInUp animate__delay-1s">
          <a 
            className="button video-action-button left"
            href="https://discord.gg/bfmgcbrCsW"
            target="_blank"
          >
            Join Us On Discord
          </a>       <a 
            className="button video-action-button right"
            href="https://www.youtube.com/watch?v=yDKpdAtLZ50"
            target="_blank"
          >
            Watch With Sound
          </a>
        </div>
     </div>
    </header>

  
      </>
  );
};
