import React from "react";
import Sora from "./../assets/sora_blushing_self.png";
import Regina from "./../assets/regina_pouting_self.png";
import Logo from "./../assets/logo/logo.png";

export const MintingSection = () => {
  return (
    <>
      <section id="gameSection">
        <div className="background-overlay">
          <div className="container">
            <div className="row align-items-start h-100">
              <div id="trailer-wrapper" className="col-xxl-6 col-xl-6 col-lg-6 
              col-md-9 col-sm-12 mx-auto left ">
              <span className="navbar-brand d-flex align-items-center">
                <img className="logo" src={Logo} />
              </span>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-9 col-sm-12 banner text-center right">
                <p className="banner_text mt-3">
                  Idol Idol is a NFT based dating game where you try your best to
                  chase the girl of your dreams before summer break begins. 
                </p>
                <p className="banner_text mt-3">
                  The game is free to play but the story line options will adapt based upon the NFTs you own. Additionally NFTs will provide staking rewards, early access and much much more.
                </p>
                <p className="banner_text mt-3">
                  Be sure to follow us on <a href="https://discord.gg/bfmgcbrCsW">Discord</a> as we will be sharing our whitepaper and more updates shortly.
                </p>               
                {/* <p className="banner_text mt-3">
                  Check out our white paper here for more details!
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section> 
      {
        /** 
         * 

     <section id="spacer-diag-bottom"></section> -->}

         */
      }
    </>
  );
};
