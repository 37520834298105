import React from "react";
import Creator1 from "./../assets/creator/1_half.png";
import Creator2 from "./../assets/creator/2_half.png";
import Creator3 from "./../assets/creator/3_half.png";
import Creator4 from "./../assets/creator/4_half.png";
import Creator5 from "./../assets/creator/5_half.png";
import { Fade } from "react-awesome-reveal";

import Char1Black from "./../assets/characters/char_01-black.png";
import Char2Black from "./../assets/characters/char_02-black.png";
import Char3Black from "./../assets/characters/char_03-black.png";
import Char4Black from "./../assets/characters/char_04-black.png";
import Char5Black from "./../assets/characters/char_05-black.png";
import Char1 from "./../assets/characters/char_01.png";
import Char2 from "./../assets/characters/char_02.png";
import Char3 from "./../assets/characters/char_03.png";
import Char4 from "./../assets/characters/char_04.png";
import Char5 from "./../assets/characters/char_05.png";
import Char1Banner from "./../assets/characters/char_01_banner.png";

export const OurCreatorSection = () => {
  const [char1Flipped, setChar1Flipped] = React.useState(false);
  const [char2Flipped, setChar2Flipped] = React.useState(false);
  const [char3Flipped, setChar3Flipped] = React.useState(false);
  const [char4Flipped, setChar4Flipped] = React.useState(false);
  const [char5Flipped, setChar5Flipped] = React.useState(false);

  const handleCard1Detail = () => {
    setChar1Flipped(!char1Flipped);
  };

  const handleCard2Detail = () => {
    setChar2Flipped(!char2Flipped);
  };

  const handleCard3Detail = () => {
    setChar3Flipped(!char3Flipped);
  };

  const handleCard4Detail = () => {
    setChar4Flipped(!char4Flipped);
  };

  const handleCard5Detail = () => {
    setChar5Flipped(!char5Flipped);
  };

  const handleChar = (
    event: React.MouseEvent<HTMLDivElement>,
    charType: string
  ) => {
    const box: HTMLDivElement = event.currentTarget;

    if (
      box?.classList.contains("card-animation") &&
      box?.classList.contains("card-front")
    ) {
      box?.classList.remove("animate__slideInUp");
      box?.classList.add("animate__slideOutDown");
      setTimeout(() => {
        const imageBox = box?.querySelector("img");
        if (imageBox) {
          switch (charType) {
            case "char1":
              imageBox.src = Char1Banner;
              break;
            case "char2":
              imageBox.src = Char1Banner;
              break;
            case "char3":
              imageBox.src = Char1Banner;
              break;
            case "char4":
              imageBox.src = Char1Banner;
              break;
            case "char5":
              imageBox.src = Char1Banner;
              break;

            default:
              break;
          }
        }
        box?.classList.remove("animate__slideOutDown");
        box?.classList.remove("animate__delay-0.5s");
        box?.classList.remove("card-front");
        box?.classList.add("animate__slideInUp");
        box?.classList.add("card-back");
      }, 1000);
    }
    if (
      box?.classList.contains("card-animation") &&
      box?.classList.contains("card-back")
    ) {
      box?.classList.remove("animate__slideInUp");
      box?.classList.add("animate__slideOutDown");
      setTimeout(() => {
        const imageBox = box?.querySelector("img");
        if (imageBox) {
          switch (charType) {
            case "char1":
              imageBox.src = Char1;
              break;
            case "char2":
              imageBox.src = Char2;
              break;
            case "char3":
              imageBox.src = Char3;
              break;
            case "char4":
              imageBox.src = Char4;
              break;
            case "char5":
              imageBox.src = Char5;
              break;

            default:
              break;
          }
        }
        box?.classList.remove("animate__slideOutDown");
        box?.classList.remove("animate__delay-0.5s");
        box?.classList.remove("card-back");
        box?.classList.add("animate__slideInUp");
        box?.classList.add("card-front");
      }, 1000);
    }

    if (
      !box?.classList.contains("animate__animated") &&
      !box?.classList.contains("card-animation")
    ) {
      box?.classList.add("animate__animated");
      box?.classList.add("animate__slideOutDown");
      box?.classList.add("animate__delay-0.5s");
      box?.classList.add("card-animation");
      box?.classList.add("card-back");

      setTimeout(() => {
        const imageBox = box?.querySelector("img");
        if (imageBox) {
          switch (charType) {
            case "char1":
              imageBox.src = Char1Banner;
              break;
            case "char2":
              imageBox.src = Char1Banner;
              break;
            case "char3":
              imageBox.src = Char1Banner;
              break;
            case "char4":
              imageBox.src = Char1Banner;
              break;
            case "char5":
              imageBox.src = Char1Banner;
              break;

            default:
              break;
          }
        }
        box?.classList.remove("animate__slideOutDown");
        box?.classList.remove("animate__delay-0.5s");
        box?.classList.add("animate__slideInUp");
      }, 1000);
    }
  };
  return (
    <>
      <section id="ourCreatorSection">
        <div className="background-overlay">
          <div className="container">
            <div className="creator-title">
              <div className="row">
                <div className="col text-center">
                  <h1 className="title mt-4">Our Creators & Influencers</h1>
                </div>
              </div>
              <div className="row social-reach-wrapper">
                {/* <div className="content-wrapper"> */}
                <div className="col text-center">
                  <h1 className="mt-4 social-title">Social Reach</h1>
                </div>
                <div className="col text-center">
                  <h1 className="mt-4">
                    <div className="container">
                      <div className="row">
                        <div className="col social-react-logo">
                          <i className="bi bi-twitter"></i>
                        </div>
                        <div className="col social-react-stats">
                          <div className="container">
                            <div className="row">
                              <div className="col follower">1.3 M+</div>
                            </div>
                            <div className="row">
                              <div className="col follower-title">
                                TWITTER FOLLOWERS
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </h1>
                </div>
                <div className="col text-center">
                  <h1 className="mt-4">
                    <div className="container">
                      <div className="row">
                        <div className="col social-react-logo">
                          <i className="bi bi-instagram"></i>
                        </div>
                        <div className="col social-react-stats">
                          <div className="container">
                            <div className="row">
                              <div className="col follower">330 K+</div>
                            </div>
                            <div className="row">
                              <div className="col follower-title">
                                INSTAGRAM FOLLOWERS
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </h1>
                </div>
                <div className="col text-center">
                  <h1 className="mt-4">
                    <div className="container">
                      <div className="row">
                        <div className="col social-react-logo">
                          <i className="bi bi-facebook"></i>
                        </div>
                        <div className="col social-react-stats">
                          <div className="container">
                            <div className="row">
                              <div className="col follower">333 K+</div>
                            </div>
                            <div className="row">
                              <div className="col follower-title">
                                FACEBOOK FOLLOWERS
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </h1>
                </div>
              </div>
              {/* </div> */}
            </div>
            <div className="creator-banner">
              <div className="col-5 text-center">
                <img src={Char1}></img>
                <div className="card-description">
                  <p className="name">Kanon Tachibana</p>
                  <p>歌音 立花</p>
                  <p className="artist artist_title"><a href="https://twitter.com/neonart0701?s=21&t=xnUl01mudOmayq1bFPWDtw" target="_blank">Artist: Neon</a></p>
             
                </div>
              </div>
              <div className="col-5 text-center">
                <img src={Char2}></img>
                <div className="card-description">
                  <p className="name">Nana Nakano</p>
                  <p>七奈 中野</p>
                  <p className="artist artist_title"><a href="https://twitter.com/hikinito0902?s=21&t=2frqCobXon1UZhY5gHcEaw" target="_blank">Artist: Leviathan</a></p>
              
                </div>
              </div>
              <div className="col-5 text-center">
                <img src={Char3}></img>
                <div className="card-description">
                  <p className="name">Miori Ueda</p>
                  <p>美織 上田</p>
                  <p className="artist artist_title"><a href="https://twitter.com/men0105?s=21" target="_blank">Artist: Sora</a></p>
    
                </div>
              </div>
              <div className="col-5 text-center">
                <img src={Char4}></img>
                <div className="card-description">
                  <p className="name">Yuka Fujiwara</p>
                  <p>優佳 藤原</p>
                  <p className="artist artist_title"><a href="https://twitter.com/nana0957?s=21&t=9f4_VAu9UwreE_Jqh1IPNg" target="_blank">Artist: Nana</a></p>
               
                </div>
              </div>
              <div className="col-5 text-center">
                <img src={Char5}></img>
                <div className="card-description">
                  <p className="name">Mao Hamasaki</p>
                  <p>浜崎 真緒</p>
                  <p className="artist artist_title"><a href="https://twitter.com/lkeris_?s=21&t=arQdCNBQbZFHgLDR4fyQBw" target="_blank">Artist: Lkeris</a></p>
              
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
