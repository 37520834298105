import React from 'react';
import "bootstrap/dist/js/bootstrap.min.js";

import "bootstrap-icons/font/bootstrap-icons.css";
import 'font-awesome/css/font-awesome.min.css';

import { Header } from './components/Header';
import { OurCreatorSection } from './components/OurCreatorSection';
import { Footer } from './components/Footer';
import { RoadmapSection } from './components/RoadmapSection';
import { TeamSection } from './components/TeamSection';
import { MintingSection } from './components/MintingSection';
import 'animate.css';


import './styles/App.scss';

function App() {
  return (
    <div className="App">
      <Header />
      <OurCreatorSection />
      <MintingSection /> 
      <TeamSection />  
      <Footer />
    </div>
  );
}

export default App;
