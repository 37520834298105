import React from 'react';
export const Footer = () => {
  return (
    <section id="footer">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="mt-4 social-link-holder">
              <a href="https://twitter.com/Idolidol_Game" target="_blank" className="footer-social-link w-inline-block"><i className="bi bi-twitter"></i></a>
              <a href="https://www.instagram.com/idolidol_official/" target="_blank" className="footer-social-link w-inline-block"><i className="bi bi-instagram"></i></a>
              <a href="https://discord.gg/bfmgcbrCsW" target="_blank" className="footer-social-link w-inline-block"><i className="bi bi-discord"></i></a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="mt-4">
            © Idol Idol 2022. All rights Reserved.
            </div>
          </div>
        </div>
        {/* <div className="row privacy">
          <div className="col">
            <div className="mt-4">
              <a href="#">Privacy Policy</a>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};
